import { Button as _Button, Badge as _Badge, Nav as _Nav } from 'react-bootstrap';

import { withShow, withTooltip } from 'bundles/hocs';
import _Link from './Link';
import _Div from './Div';
import _Span from './Span';
import _Th from './Th';
import _Td from './Td';

_Nav.Item = withShow(_Nav.Item);
_Nav.Link = withShow(_Nav.Link);

export const Button = withShow(withTooltip(_Button));
export const Nav: any = _Nav;
export const Badge = withShow(_Badge);

export const Link = withShow(withTooltip(_Link));
export const Div = withShow(_Div);
export const Span = withShow(_Span);
export const Th = withShow(_Th);
export const Td = withShow(_Td);
